/** @type {import('tailwindcss').Config} */

import tailwindForms from '@tailwindcss/forms'

export default {
  content: [
    './index.html',
    './src/**/*.{js,ts,jsx,tsx}',
    './node_modules/tailwind-datepicker-react/dist/**/*.js',
  ],
  darkMode: 'class',
  theme: {
    extend: {
      animation: {
        'spin-slow': 'spin 2s linear infinite',
      },
      colors: {
        midnight: {
          50: '#e5f3ff',
          100: '#d0e9ff',
          200: '#aad5ff',
          300: '#77b6ff',
          400: '#4285ff',
          500: '#1854ff',
          600: '#0037ff',
          700: '#003aff',
          800: '#0033e0',
          900: '#0224ad',
          950: '#010b3b',
          light: '#77b6ff',
          DEFAULT: '#010b3b',
        },
        brownGray: {
          50: '#f7f7f5',
          100: '#e7e7e3',
          200: '#d9d9d4',
          300: '#bdbdb4',
          400: '#a2a195',
          500: '#8f8d80',
          600: '#827f74',
          700: '#6d6b61',
          800: '#5a5852',
          900: '#4a4844',
          950: '#272623',
          light: '#f7f7f5',
          DEFAULT: '#d9d9d4',
          dark: '#5a5852',
        },
        gold: {
          50: '#fbf9f1',
          100: '#f5f0df',
          200: '#e9ddbf',
          300: '#dac393',
          400: '#cca76b',
          500: '#c1904e',
          600: '#b37b43',
          700: '#956339',
          800: '#785034',
          900: '#62432c',
          950: '#342116',
          light: '#f5f0df',
          DEFAULT: '#dac393',
          dark: '#857347',
        },
        brownGold: {
          50: '#f7f5ef',
          100: '#eae7d7',
          200: '#dac393',
          300: '#c0b284',
          400: '#ad9962',
          500: '#a38b57',
          600: '#876d47',
          700: '#6d553b',
          800: '#5d4836',
          900: '#513f32',
          950: '#2e221a',
          light: '#dac393',
          DEFAULT: '#a38b57',
          dark: '#5d4836',
        },
        blue: {
          50: '#f2f8fd',
          100: '#e4effa',
          200: '#c4daff',
          300: '#8fc6ea',
          400: '#53a9dd',
          500: '#3697d3',
          600: '#1d71ac',
          700: '#195b8b',
          800: '#045485',
          900: '#1a4160',
          950: '#031053',
          light: '#c4daff',
          DEFAULT: '#3697d3',
          dark: '#1a4160',
        },
        charcoal: {
          50: '#f5f5f6',
          100: '#e6e6e7',
          200: '#d0d0d1',
          300: '#afafb1',
          400: '#848488',
          500: '#6b6b6f',
          600: '#5d5d60',
          700: '#4e4e50',
          800: '#454446',
          900: '#3B3B3E',
          950: '#18181C',
          light: '#C3C3CA',
          DEFAULT: '#5d5d60',
          dark: '#18181C',
        },
        white: {
          light: '#FDFDF9',
          DEFAULT: '#FAFAF4',
          dark: '#f0f0ed',
        },
        green: {
          50: '#f3ffe4',
          100: '#e4ffc4',
          200: '#caff90',
          300: '#a5ff50',
          400: '#83fe1d',
          500: '#62e500',
          600: '#4ab800',
          700: '#358500',
          800: '#2f6d07',
          900: '#295c0b',
          950: '#113400',
          light: '#f3ffe4',
          DEFAULT: '#358500',
          dark: '#2f6d07',
        },
        red: {
          50: '#fff0f2',
          100: '#ffdde3',
          200: '#ffc1cb',
          300: '#ff95a7',
          400: '#ff5974',
          500: '#ff264a',
          600: '#fc062f',
          700: '#e00025',
          800: '#af0521',
          900: '#900c22',
          950: '#50000d',
          light: '#fff0f2',
          DEFAULT: '#e00025',
          dark: '#900c22',
        },
        error: {
          light: '#fff0f2',
          DEFAULT: '#e00025',
          dark: '#900c22',
        },
        success: {
          light: '#f3ffe4',
          DEFAULT: '#358500',
          dark: '#2f6d07',
        },
        background: {
          DEFAULT: '#F0F0ED',
        },
      },
      fontSize: {
        xs: '11px',
        sm: '14px',
        md: '16px',
        base: '16px',
        lg: '18px',
        xl: '20px',
        '2xl': '24px',
        '3xl': '32px',
        '4xl': '48px',
        '5xl': '64px',
        '6xl': '92px',
      },
      lineHeight: {
        9: '35px',
        11: '46px',
        12: '60px',
        13: '80px',
        'leading-none': '1',
        'leading-tight': '16px',
        'leading-snug': '18px',
        'leading-normal': '20px',
        'leading-relaxed': '22px',
        'leading-loose': '24px',
      },
      screens: {
        tall: { raw: '(min-height: 700px)' },
        short: { raw: '(max-height: 700px)' },
      },
    },
  },
  // eslint-disable-next-line global-require
  plugins: [tailwindForms],
}
