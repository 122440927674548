/* @flow */
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

// Components
import { Button } from '../../components/Button'
import { PasswordInput } from '../../components/PasswordInput'
import { TextInput } from '../../components/TextInput'

// Service
import { getSSOLoginUrl, loginWithCredentials, loginWithSSO } from '../../services/user.service'

// Store
import { UserStoreContext } from '../../stores/UserStore'

// Utils
import { toast } from '../../utils/helpers'

/**
 *
 * Login
 *
 */
const Login = () => {
  // Context
  const location = useLocation()
  const navigate = useNavigate()
  const { setCurrentUser, getUpdatedUser, setCurrentTokens } = useContext(UserStoreContext)

  // State
  const [loading, setLoading] = useState(false)
  const [SSOloading, setSSOLoading] = useState(false)

  const handleSuccesses = (message) => toast(message, 'success')
  const handleErrors = (message) => toast(message, 'error')

  useEffect(() => {
    if (!location.pathname.includes('login')) navigate('/login', { replace: true })
  }, [location])

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  /**
   * Handles form submission
   * - Redirects to verification page if successful
   * @param {object} data
   */
  const onSubmit = async (data) => {
    const loginData = await loginWithCredentials(data, handleErrors, setLoading)

    if (loginData) {
      setCurrentUser({ email: data.email, ...loginData })
      navigate('/verification', { state: { password: data.password, next: location.state?.next } })
    }
  }

  /**
   * When logging in via Office 365, get the authorization URL and redirect to it.
   */
  const getAndRedirectToO365 = async () => {
    const response = await getSSOLoginUrl(handleErrors, setSSOLoading)
    window.location = response.authorizationUrl
  }

  /**
   * Check if the user has been redirected to this page with a URL parameter
   *
   * - `confirmed` indicates that a user has been sent to this page from `ConfirmAccount`
   * - `reset` indicates that a user has been sent to this page from `ResetPassword`
   * - `created` indicates that a user has been sent to this page from an email confirmation link
   */
  useEffect(() => {
    if (location && location.search === '?confirmed=true')
      handleSuccesses('Your email address has been confirmed. You may now log in.')
    else if (location && location.search === '?reset=true')
      handleSuccesses('Your password has been changed. You may now log in.')
    else if (location && location.search === '?created=true')
      handleSuccesses('Your account has been created. You may now log in.')
    else if (location && location.search.startsWith('?code=')) {
      const params = new URLSearchParams(location.search)
      const data = {
        code: params.get('code'),
        state: params.get('state'),
        // Must be lower_snake_case , as this is sent as query string params and does not get
        // auto-converted from camelCase
        session_state: params.get('session_state'),
      }

      const result = loginWithSSO(data, handleErrors, setLoading, setCurrentTokens, getUpdatedUser)
      if (result) {
        navigate('/dashboard')
      }
    }
  }, [])

  return (
    <div className="flex h-full w-full justify-center px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md">
        <form
          className="mt-8 flex flex-col space-y-5"
          id="login-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* Form Fields */}
          <div>
            <TextInput
              className="bg-brownGray-100"
              data-testid="email"
              error={errors.email && 'This field is required'}
              autoComplete="email"
              label="Email"
              id="email"
              name="email"
              placeholder="Email"
              required
              {...register('email', { required: true })}
            />

            <Controller
              error={errors.password && 'This field is required'}
              name="password"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <PasswordInput
                  className="bg-brownGray-100"
                  error={errors.password && (errors.password.message || 'This field is required')}
                  id="password"
                  label="Password"
                  // inputStyles="bg-transparent text-primary placeholder:text-gray-600 placeholder:text-sm placeholder:font-normal"
                  placeholder="Password"
                  autoComplete="new-password"
                  showTooltip={false}
                  {...field}
                />
              )}
            />
          </div>

          <Button fullWidth label="Sign in" loading={loading} />

          <div className="self-center">
            <a
              href="/password-reset-request"
              className="text-sm font-semibold uppercase leading-[14px] tracking-[1.5px] text-blue-800 hover:text-blue-900"
            >
              Forgot password
            </a>
          </div>
        </form>

        <div className="my-4 flex justify-center">
          <div className="m-auto w-32 flex-auto">
            <hr className="border-2"></hr>
          </div>
          <div className="w-12 flex-none text-center font-bold text-slate-500">OR</div>
          <div className="m-auto w-32 flex-auto">
            <hr className="border-2"></hr>
          </div>
        </div>

        <Button
          fullWidth
          label="Sign in with Office 365"
          onClick={() => {
            getAndRedirectToO365()
          }}
          disabled={SSOloading}
        />

        <div className="h-[50px]" />
      </div>
    </div>
  )
}

export default Login
