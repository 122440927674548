/* eslint-disable camelcase */
/* eslint-disable no-throw-literal */
import jwt_decode from 'jwt-decode'

/** @todo Fix import cycle */
// eslint-disable-next-line import/no-cycle
import axios from './instance'
import { getErrorMessage } from '../utils/helpers'

// --------------------------- User Authentication with SSO (WPC Users) ---------------------------

/**
 * Retrieve the SSO login URL
 *
 * @param {*} setError
 * @param {*} setLoading
 * @returns `data` results or null
 */
export const getSSOLoginUrl = async (setError, setLoading) => {
  setLoading(true)

  try {
    const data = await axios.get(`/auth/o/microsoft-graph/?redirect_uri=${window.location.origin}`)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Logs in a user with the specified `params` (code, state, session_state)
 *
 * @param {object} params
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setCurrentTokens
 * @param {func} getUpdatedUser
 * @returns `data` results or null
 */
export const loginWithSSO = async (
  params,
  setError,
  setLoading,
  setCurrentTokens,
  getUpdatedUser,
) => {
  setLoading(true)

  try {
    const data = await axios.post(
      `/auth/o/microsoft-graph/?code=${params.code}&state=${params.state}&session_state=${params.session_state}`,
    )

    setCurrentTokens(data)

    // Get updated user
    const token = { ...data }.access
    const decoded = jwt_decode(token)
    getUpdatedUser(decoded.user_id)

    setLoading(false)

    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)

    return null
  }
}

// ------------------------ User Authentication with Tokens (Dealer Users) ------------------------

/**
 * Creates a new account with the specified `user` information.
 * @param {object} user
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const addUser = async (user, setError, setLoading, setSuccess) => {
  setLoading(true)

  try {
    const data = await axios.post('/users/?expand=dealer_user_profile.dealers/', user)
    setSuccess('New account created.')
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Requests verification code for a user with the specified `credentials`.
 * @param {object} credentials
 * @param {func} setError
 * @param {func} setLoading
 * @returns {object} with MFA properties (message, method and obfuscated value)
 */
export const loginWithCredentials = async (credentials, setError, setLoading) => {
  setLoading(true)

  try {
    const data = await axios.post('/login/', credentials)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Re-requests verification code (with optionally-specified MFA device type) and logs in a user with the specified `credentials`.
 * @param {object} credentials
 * @param {func} setError
 * @param {func} setLoading
 * @returns {object} with MFA properties (message, method and obfuscated value)
 */
export const resendLoginCode = async (credentials, setError, setLoading) => {
  setLoading(true)

  try {
    const data = await axios.post('/login/resend/', credentials)
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Submits verification code and logs in a user with the specified `credentials`.
 * @param {object} credentials
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setCurrentUser
 * @param {func} setCurrentTokens
 * @param {func} getUpdatedUser
 */
export const loginConfirm = async (
  payload,
  setError,
  setLoading,
  setCurrentTokens,
  getUpdatedUser,
) => {
  setLoading(true)

  try {
    const data = await axios.post('/login/confirm/', payload)
    setCurrentTokens(data)

    // Get updated user
    const token = { ...data }.access
    const decoded = jwt_decode(token)
    getUpdatedUser(decoded.user_id)

    setLoading(false)

    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)

    return null
  }
}

/**
 * Requests a password reset for the specified user
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const forgotPassword = async (payload, setError, setLoading, setSuccess) => {
  setLoading(true)

  try {
    const { data } = await axios.post('/password_reset/', payload)
    setSuccess('Check your email to reset your password.')
    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Confirms the user's new password
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setCurrentTokens
 * @param {func} getUpdatedUser
 * @return whether it was successful
 */
export const resetPassword = async (
  payload,
  setError,
  setLoading,
  setCurrentTokens,
  getUpdatedUser,
) => {
  setLoading(true)

  try {
    const data = await axios.post('/password_reset/confirm/', payload)
    setCurrentTokens(data)

    // Get updated user
    const token = { ...data }.access
    const decoded = jwt_decode(token)
    getUpdatedUser(decoded.user_id)

    setLoading(false)
    return true
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return false
  }
}

/**
 * Validates the specified `token` for the reset password flow
 * @param {string} token
 * @param {func} showError
 * @return whether it was successful
 */
export const validateResetToken = async (token, showError) => {
  try {
    await axios.post('/password_reset/validate_token/', { token })
    return true
  } catch (err) {
    showError(getErrorMessage(err))
    return false
  }
}

/**
 * Refreshes the specified `refresh` token
 * @param {string} refresh
 */
export const refreshAccessToken = async (refresh) => {
  try {
    return axios.post('/token/refresh/', { refresh })
  } catch (err) {
    throw {
      error: true,
      // Ensure that we are consistently returning an object, not a string
      details: typeof details === 'object' ? err.response.data : { error: err.response.data },
    }
  }
}

/**
 *
 * If possible, revoke the user's session.
 * In all cases, remove the user's data, including auth tokens, by clearing the store. This triggers
 * a change in PrivateRoute and redirects the user.
 *
 * @param {string} refresh - The user's refresh token
 * @param {function} clearStore - Function from RootStore to clear out all store data
 */
export const logout = async (refresh, clearStore = () => {}) => {
  try {
    await axios.post('/logout/', { refresh })
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn('User session could not be revoked.')
  }

  clearStore()
}

// --------------------------------- User Information ---------------------------------

/**
 * Get the specified `user`'s information
 * @param {object} user
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @param {func} setCurrentUser
 * @returns `data` results
 */
export const getUser = async (
  user,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
  setCurrentUser = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(`/users/${user.id}/`)

    setCurrentUser(data.user)
    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates the specified `user`'s information
 * @param {object} user
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @param {func} setCurrentUser
 * @returns `data` results
 */
export const updateUser = async (
  user,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
  setCurrentUser = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.patch(`/users/${user.id}/`, user)

    setCurrentUser(data)
    setLoading(false)
    setSuccess('User information updated.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Update the specified the password of the authenticated user
 * @param {object} data
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const updateUserPassword = async (data, setError, setLoading, setSuccess) => {
  setLoading(true)

  try {
    const response = await axios.put('/update-password/', data)
    setLoading(false)
    setSuccess(response.message)
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}

/**
 * Get list of users
 * @param {object} user
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getUsers = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(url)

    setLoading(false)
    setSuccess(data)

    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

// --------------------------------- User Deletion ---------------------------------------

/**
 * Deletes the specified `user`
 * @param {string} userId
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const deleteUser = async (userId, setError, setLoading, setSuccess) => {
  setLoading(true)

  try {
    const data = await axios.delete(`/users/${userId}/`)

    setLoading(false)
    setSuccess('User deleted.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

// --------------------------------- Change MFA Settings ---------------------------------

/**
 * Requests an email change with the specified `email` for the authenticated user
 * @param {string} email
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const requestEmailChange = async (
  email,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(`/change_email/`, { email })

    setLoading(false)
    setSuccess(data.message)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Requests an phone change with the specified `phone` for the authenticated user
 * @param {string} phoneNumber
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const requestPhoneChange = async (
  phoneNumber,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(`/change_phone_number/`, { phoneNumber })

    setLoading(false)
    setSuccess(data.message ? data.message : 'Phone number removed.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Submits the specified verification `code`.
 * @param {string} code
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @param {func} setCurrentUser
 * @returns `data` results
 */
export const confirmEmailChange = async (
  code,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
  setCurrentUser = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(`/change_email/confirm/`, { code })

    setCurrentUser(data)
    setLoading(false)
    setSuccess('Email address updated.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Submits the specified verification `code`.
 * @param {string} code
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @param {func} setCurrentUser
 * @returns `data` results
 */
export const confirmPhoneChange = async (
  code,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
  setCurrentUser = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.post(`/change_phone_number/confirm/`, { code })

    setCurrentUser(data)
    setLoading(false)
    setSuccess('Phone number updated.')
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

// --------------------------------- Unused ---------------------------------

export function confirmAccountOrEmail(token, uid) {
  return axios.get(`/confirm/?token=${token}&uid=${uid}`)
}
