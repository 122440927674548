import dayjs from 'dayjs'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

// Components
import { Button } from '../../components/Button'
import { PaperAirplaneIcon } from '../../components/PaperAirplaneIcon'
import { TextInput } from '../../components/TextInput'

// Stores
import { UserStoreContext } from '../../stores/UserStore'

// Utils & Service
import { createComment, getComments } from '../../services/repairOrders.service'
import { toast } from '../../utils/helpers'

const RepairOrderChat = ({ dealerId, repairOrderId, isRepairOrderResolved }) => {
  // State
  const [chatComments, setChatComments] = useState([])
  const [nextUrl, setNextUrl] = useState(null)
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [shouldChatScroll, setShouldChatScroll] = useState(true)

  // Context
  const { user } = useContext(UserStoreContext)

  // Ref
  const commentsRef = useRef(null)

  const handleError = (message) => toast(message, 'error')

  let lastDay = null

  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      message: '',
    },
  })

  /**
   * Scroll to the last comment when chatComments change and shouldChatScroll is true.
   */
  useEffect(() => {
    if (chatComments.length > 0 && shouldChatScroll) {
      const lastCommentElement = commentsRef.current.lastChild
      lastCommentElement?.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [chatComments])

  useEffect(() => {
    const fetchComments = async () => {
      const response = await getComments(dealerId, repairOrderId, 1, setLoading, handleError)

      // Reverse the comments so the latest one is at the bottom
      setChatComments(_.reverse(response.results))
      setNextUrl(response.next)
    }

    fetchComments()
  }, [dealerId, repairOrderId])

  const loadMessages = async () => {
    const response = await getComments(
      dealerId,
      repairOrderId,
      currentPage + 1,
      setLoading,
      handleError,
      () => {
        setCurrentPage((prevPage) => prevPage + 1)

        // Reverse the comments so the latest one is at the bottom
        setChatComments((prevMessages) => [..._.reverse(response.results), ...prevMessages])
      },
    )

    setNextUrl(response.next)
  }

  const handleLoadMore = () => {
    if (nextUrl && !loading) {
      loadMessages(nextUrl)
      setShouldChatScroll(false)
    }
  }

  /**
   * Handle form submission for new comment.
   * @param {object} data - The form data.
   */
  const onSubmit = async ({ message }) => {
    if (message.trim()) {
      const tempId = Date.now()
      const newComment = {
        id: tempId,
        message,
        status: 'sending',
        user: { id: user.id, firstName: user.firstName, lastName: user.lastName },
      }
      setChatComments([...chatComments, newComment])

      const response = await createComment(
        dealerId,
        repairOrderId,
        { message },
        setLoading,
        (errorMessage) => {
          setChatComments((prevComments) =>
            prevComments.map((comment) =>
              comment.id === tempId ? { ...comment, status: 'error' } : comment,
            ),
          )
          handleError(errorMessage)
        },
      )

      if (response) {
        setChatComments((prevComments) =>
          prevComments.map((comment) => (comment.id === tempId ? response : comment)),
        )
        setShouldChatScroll(true)
        reset()
      }
    }
  }

  /**
   * Render user name or "You" if the comment is by the logged-in user.
   * @param {object} commentUser - The user who made the comment.
   * @returns {string} - The user's name or "You".
   */
  const renderUserName = (commentUser) => {
    if (commentUser.id === user.id) {
      return 'You'
    }
    return `${commentUser.firstName} ${commentUser.lastName}`
  }

  const renderCommentText = (comment) => {
    if (comment.status === 'sending') {
      return {
        visibleText: 'Sending...',
        screenReaderText: `${renderUserName(comment.user)} is sending a message.`,
      }
    }
    if (comment.status === 'error') {
      return {
        visibleText: 'Failed to send',
        screenReaderText: `${renderUserName(comment.user)} tried to send a message but it failed.`,
      }
    }
    const formattedTime = dayjs(comment.createdAt).format('h:mm A')
    return {
      visibleText: `${renderUserName(comment.user)} - ${formattedTime}`,
      screenReaderText: `${renderUserName(comment.user)} commented at ${formattedTime}:`,
    }
  }

  return (
    <aside
      className="bg-midnight-950/90 relative ml-0 mt-6 h-full md:ml-4 md:h-auto md:min-w-[489px]"
      data-testid="chatContainer"
    >
      <div className="absolute inset-y-0 right-0 top-1 flex w-full grow flex-col">
        <div className="grow overflow-y-auto p-4" id="chatContainer">
          {chatComments.length === 0 && (
            <p className="text-charcoal-light mb-8 text-center text-sm">No messages.</p>
          )}
          {nextUrl && (
            <Button
              type="button"
              onClick={handleLoadMore}
              loading={loading}
              label="Show Older Mesages"
              outlined
              background="bg-white"
              size="xs"
              className="m-auto mb-4 text-center "
            />
          )}
          <ol className="flex flex-col" ref={commentsRef}>
            {chatComments.map((comment) => {
              const currentDay = dayjs(comment.createdAt).format('ddd, MMM D')
              let dayChanged = false
              if (lastDay !== currentDay) {
                lastDay = currentDay
                dayChanged = true
              }
              return (
                <React.Fragment key={comment.id}>
                  {dayChanged && (
                    <li className="text-charcoal-light mb-8 text-center text-xs">
                      {dayjs(comment.createdAt).format('ddd, MMM D h:mm A')}
                    </li>
                  )}

                  <li
                    className={`pb-6 ${
                      comment.user.id === user.id ? 'self-end text-right' : 'self-start text-left'
                    }`}
                  >
                    <div
                      className={`mb-2 w-[271px] break-words p-2 text-sm ${
                        comment.user.id === user.id
                          ? 'self-start rounded-b-md rounded-tl-md bg-white text-right text-black'
                          : 'self-end rounded-t-md rounded-br-md bg-blue-500 text-white'
                      }`}
                    >
                      <p className="sr-only">{renderCommentText(comment).screenReaderText}</p>
                      <p>{comment.message}</p>
                    </div>

                    <p
                      className={`text-charcoal-light text-xs ${
                        comment.user.id === user.id ? 'text-right' : ''
                      }`}
                      aria-hidden="true"
                    >
                      {renderCommentText(comment).visibleText}
                    </p>
                  </li>
                </React.Fragment>
              )
            })}
          </ol>
        </div>

        <div className="bg-midnight-950 w-full p-4">
          {isRepairOrderResolved ? (
            <div className="flex items-center justify-center">
              <p className="text-md text-white">
                Chat is disabled because repair order is resolved.
              </p>
            </div>
          ) : (
            <form className="flex w-full items-center" onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                placeholder="Message"
                name="message"
                hideError
                fullWidth
                {...register('message', { required: true })}
              />
              <button
                aria-label="Send message"
                type="submit"
                loading={loading}
                className="ml-2 flex h-12 items-center justify-center rounded-lg bg-blue-800 px-4 text-white"
              >
                <PaperAirplaneIcon className="h-4 text-white" />
              </button>
            </form>
          )}
        </div>
      </div>
    </aside>
  )
}

RepairOrderChat.propTypes = {
  dealerId: PropTypes.string.isRequired,
  repairOrderId: PropTypes.string.isRequired,
  isRepairOrderResolved: PropTypes.bool.isRequired,
}

export default RepairOrderChat
