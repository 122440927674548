import { Description, Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

// Components
import { Button } from '../Button'

const Modal = ({ actions, description, content, loading, onClose, open, setOpen, title }) => {
  const renderActions = () => {
    const submit = _.find(actions, (a) => a.type === 'submit')
    const cancel = _.find(actions, (a) => a.type === 'cancel')
    const deleteAction = _.find(actions, (a) => a.type === 'delete')

    if (!submit && !deleteAction) {
      return (
        <div className="mt-5 sm:mt-6">
          <Button
            background="bg-white"
            fullWidth
            loading={loading}
            onClick={() => {
              if (cancel.onClick) cancel.onClick()
              setOpen(false)
            }}
            outlined
            label={cancel.label}
          />
        </div>
      )
    }

    if (deleteAction) {
      return (
        <>
          <div className="mt-5 space-y-3 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 sm:space-y-0">
            <Button
              background="bg-white"
              fullWidth
              loading={loading}
              onClick={() => {
                if (cancel.onClick) cancel.onClick()

                setOpen(false)
              }}
              outlined
              label={cancel.label}
            />

            <Button fullWidth onClick={() => submit.onClick()} label={submit.label} />
          </div>

          <Button
            background="bg-red"
            fullWidth
            loading={loading}
            onClick={() => deleteAction.onClick()}
            label={deleteAction.label}
          />
        </>
      )
    }

    if (!cancel) {
      return (
        <div className="flex w-full justify-center">
          <Button
            fullWidth
            onClick={submit.onClick}
            label={submit.label}
            loading={loading}
            size="sm"
          />
        </div>
      )
    }
    return (
      <div className="space-y-3 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 sm:space-y-0">
        <Button
          background="bg-background"
          fullWidth
          loading={loading}
          onClick={() => {
            if (cancel.onClick) cancel.onClick()
            setOpen(false)
          }}
          outlined
          label={cancel.label}
          size="sm"
        />

        <Button
          fullWidth
          onClick={submit.onClick}
          label={submit.label}
          loading={loading}
          size="sm"
        />
      </div>
    )
  }

  /**
   * Renders a Modal Description if one is provided.
   *
   * - If the description is an array, maps out the provided strings with vertical spacing.
   * - Otherwise, renders a single description.
   */
  const renderDescription = () => {
    if (description) {
      if (_.isArray(description))
        return (
          <div className="mt-4 space-y-2">
            {description.map((d) => (
              <Description className="text-sm font-normal text-gray-500">{d}</Description>
            ))}
          </div>
        )

      return (
        <Description className="mt-4 text-center text-sm font-normal italic text-gray-500">
          {description}
        </Description>
      )
    }

    return null
  }

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          if (onClose) onClose()
          else setOpen(false)
        }}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="bg-white-dark relative w-full rounded-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                <div>
                  <div className="flex flex-row content-center items-center justify-center space-x-2">
                    <Dialog.Title className="text-2xl font-bold leading-none text-gray-700">
                      {title}
                    </Dialog.Title>
                  </div>

                  {renderDescription()}

                  {content}
                </div>

                <div className="flex justify-end space-y-3">{renderActions()}</div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

Modal.defaultProps = {
  actions: [
    { type: 'cancel', label: 'Cancel' },
    { type: 'submit', label: 'Save' },
  ],
  description: null,
  loading: false,
  onClose: null,
  open: false,
}

Modal.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  content: PropTypes.node.isRequired,
  description: PropTypes.string,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

export default Modal
