import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { Route, Routes } from 'react-router-dom'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Pages
import { Dashboard } from '../Dashboard'
import { DealerDetails } from '../DealerDetails'
import { DealerUsers } from '../DealerUsers'
import { Login } from '../Login'
import { MultiFactorAuthentication } from '../MultiFactorAuthentication'
import { NotFound } from '../NotFound'
import { PasswordReset } from '../PasswordReset'
import { PasswordResetRequest } from '../PasswordResetRequest'
import { RepairOrder } from '../RepairOrder'
import { Settings } from '../Settings'
import { UserProfile } from '../UserProfile'

// Components
import { NavBar } from '../../components/NavBar'
import { PrivateRoute } from '../../components/PrivateRoute'
import { PublicRoute } from '../../components/PublicRoute'
import { TaskProgress } from '../../components/TaskProgress'

// Store
import { UserStoreContext } from '../../stores/UserStore'

/**
 *
 * Routes
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
const PageRoutes = observer(() => {
  // Context
  const { isAuthenticated, isDealerUser } = useContext(UserStoreContext)

  return (
    <div className="h-screen w-screen bg-white">
      <Helmet
        titleTemplate="%s - Warranty Processing Portal"
        defaultTitle="Warranty Processing Portal"
      >
        <meta name="description" content="Warranty Processing Portal" />
      </Helmet>

      <NavBar
        options={[
          { label: 'Dashboard', path: '/dashboard' },
          ...(isDealerUser ? [] : [{ label: 'Dealer Users', path: '/dealer-users' }]),
        ]}
        orientation="horizontal"
      />

      <div
        className={mergeClassNames(
          isAuthenticated
            ? 'bg-background h-[calc(100vh-64px)]'
            : 'min-h-[calc(100vh-200px)] bg-white md:min-h-[calc(100vh-200px)] lg:min-h-[calc(100vh-290px)]',
        )}
      >
        <Routes>
          {/* Main Routes */}
          <Route path="*" element={<NotFound />} />

          {/* Public Routes */}
          <Route element={<PublicRoute component={Login} />} caseSensitive path="/" />
          <Route element={<PublicRoute component={Login} />} caseSensitive path="/login" />
          <Route
            element={<PublicRoute component={MultiFactorAuthentication} />}
            caseSensitive
            path="/verification"
          />
          {/* <Route element={<PublicRoute component={SignUp} />} caseSensitive path="/sign-up" /> */}
          <Route
            element={<PublicRoute component={PasswordReset} />}
            caseSensitive
            path="/password-reset"
          />
          <Route
            element={<PublicRoute component={PasswordReset} />}
            caseSensitive
            path="/set-password"
          />
          <Route
            element={<PublicRoute component={PasswordResetRequest} />}
            caseSensitive
            path="/password-reset-request"
          />

          <Route element={<PrivateRoute component={Dashboard} />} caseSensitive path="/" />

          {/* Private Routes */}
          <Route
            element={<PrivateRoute component={Dashboard} />}
            caseSensitive
            path="/dashboard"
          />

          <Route
            caseSensitive
            path="/profile"
            element={<PrivateRoute component={UserProfile} />}
          />
          <Route caseSensitive path="/settings" element={<PrivateRoute component={Settings} />} />

          <Route path="/dealer/:id/detail" element={<PrivateRoute component={DealerDetails} />} />
          <Route
            path="/dealer/:dealerId/repair-order/create"
            element={<PrivateRoute component={RepairOrder} roles={['WPC User']} />}
          />
          <Route
            path="/dealer/:dealerId/repair-order/:id"
            element={<PrivateRoute component={RepairOrder} />}
          />
          <Route
            path="/dealer-users"
            element={<PrivateRoute component={DealerUsers} roles={['WPC User']} />}
          />
        </Routes>
      </div>

      <TaskProgress />
    </div>
  )
})

export default PageRoutes
