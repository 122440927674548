import * as Sentry from '@sentry/browser'
import { action, computed, observable } from 'mobx'
import { createContext } from 'react'

// Store
import { BaseStore } from './BaseStore'

// Service & Utils
// eslint-disable-next-line import/no-cycle
import { getUser, updateUser } from '../services/user.service'
import { getErrorMessage } from '../utils/helpers'

const INITIAL_STATE = {
  accessToken: '',
  refreshToken: '',
  user: {},
  loading: false,
  lastUpdated: null,
  error: null,
}

const TYPES = {
  accessToken: observable,
  refreshToken: observable,
  user: observable,
  loading: observable,
  error: observable,
  lastUpdated: observable,
  isAuthenticated: computed,
  isDealerUser: computed,
  hasAcceptedTermsAndConditions: computed,
}

export class UserStore extends BaseStore {
  get isAuthenticated() {
    return this.accessToken !== ''
  }

  get isDealerUser() {
    if (this.user && this.user.id) {
      return this.user.userType === 'Dealer User'
    }
    return null
  }

  get hasAcceptedTermsAndConditions() {
    if (this.user && this.user.id) {
      return !!this.user.acceptedTermsAndConditionsAt
    }
    return null
  }

  /**
   * Updates the stored user data.
   * @param {object} payload
   */
  updateUser = action(async (payload) => {
    this.loading = true
    this.error = null

    try {
      const currentUser = await updateUser({ ...user, ...payload })
      this.user = currentUser

      Sentry.configureScope((scope) => {
        scope.setUser({
          ...currentUser,
        })
      })

      this.lastUpdated = new Date()
      this.loading = false
    } catch (err) {
      this.error = getErrorMessage(err)
      this.loading = false
    }
  })

  /**
   * Gets updated user data with the specified `id`.
   * @param {string} id
   */
  getUpdatedUser = action(async (id) => {
    this.loading = true
    this.error = null

    try {
      const currentUser = await getUser({ id })
      this.user = currentUser

      Sentry.configureScope((scope) => {
        scope.setUser({
          ...currentUser,
        })
      })

      this.lastUpdated = new Date()
      this.loading = false
    } catch (err) {
      this.error = getErrorMessage(err)
      this.loading = false
    }
  })

  setCurrentTokens = action(({ access, refresh }) => {
    this.accessToken = access
    this.refreshToken = refresh
  })

  setCurrentUser = action((user) => {
    this.user = {
      ...this.user,
      ...user,
    }
  })
}

export const user = new UserStore(INITIAL_STATE, TYPES)
export const UserStoreContext = createContext(user)
