import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

// Components
import { AutoCompleteCombobox } from '../../components/AutoCompleteCombobox'
import { DatePicker } from '../../components/DatePicker'
import { Modal } from '../../components/Modal'
import { TextInput } from '../../components/TextInput'
import { Toggle } from '../../components/Toggle'

// Utils & Services
import { updateRepairOrder } from '../../services/repairOrders.service'
import { toast } from '../../utils/helpers'

const RepairOrderModal = ({
  isOpen,
  setIsOpen,
  getUpdatedRepairOrder,
  repairOrder,
  closeModal,
  allAdvisors,
  dealer,
}) => {
  // State
  const [loading, setLoading] = useState(false)
  const [advisorQuery, setAdvisorQuery] = useState('')
  const [filteredAdvisors, setFilteredAdvisors] = useState([])
  const [showDatePicker, setShowDatePicker] = useState(false)

  const handleSuccess = (message) => {
    toast(message, 'success')
    closeModal()
    getUpdatedRepairOrder()
  }
  const handleError = (message) => toast(message, 'error')

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
    reset,
    control,
  } = useForm({
    defaultValues: {
      roNumber: repairOrder?.roNumber || '',
      advisor: repairOrder?.advisor || '',
      isDateAddedOverridden: repairOrder?.isDateAddedOverridden,
      isAgesOutOverridden: repairOrder?.isAgesOutOverridden,
      timeClockTriggerDate: repairOrder?.timeClockTriggerDate,
      isOpen: repairOrder?.isOpen,
      agesOut: repairOrder?.agesOut,
    },
  })

  /**
   * Handles updating the existing repair order.
   * @param {object} data
   * @returns
   */
  const handleFormSubmit = async (data) => {
    const payload = {
      ...data,
      roNumber: data.roNumber.toUpperCase(),
      timeClockTriggerDate: dayjs(data.timeClockTriggerDate).format('YYYY-MM-DD'),
      agesOut: dayjs(data.agesOut).format('YYYY-MM-DD'),
    }

    // If we are marking `isAgesOutOverridden` as false and we already have a
    // value for `agesOut`, we need to clear it out
    if (!data.isAgesOutOverridden && data.agesOut) {
      payload.agesOut = null
    }

    await updateRepairOrder(dealer.id, repairOrder.id, payload, setLoading, handleError, () => {
      handleSuccess('Repair Order updated successfully.')
    })
  }

  const renderRepairOrderDateField = () => {
    const isAgesOutOverridden = watch('isAgesOutOverridden')
    const trigger = dealer?.manufacturer.timeClockTrigger

    // Conditional message for RO close date
    if (watch('isOpen') && !isAgesOutOverridden && trigger === 'RO close date') {
      return (
        <span className="mb-10 text-center text-xs font-light italic leading-5 tracking-[1.25px]">
          For this manufacturer, all open Repair Orders will age out 30 days after the date added
          to the Outstanding List.
        </span>
      )
    }

    if (isAgesOutOverridden) {
      // If agesOut is overridden, show the agesOut field
      return (
        <Controller
          name="agesOut"
          control={control}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              disabled={loading}
              dataTestId="agesOut"
              error={
                errors.agesOut
                  ? { message: errors.agesOut.message || 'This field is required' }
                  : null
              }
              label="Age Out Date Override (MM/DD/YYYY)"
              onChange={(date) => onChange(dayjs(date).format('YYYY-MM-DD'))}
              show={showDatePicker}
              setShow={setShowDatePicker}
              value={value ? dayjs(value).format('YYYY-MM-DD') : null}
            />
          )}
          rules={{
            required: true,
          }}
        />
      )
    }
    return (
      <Controller
        name="timeClockTriggerDate"
        control={control}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            disabled={loading}
            dataTestId="timeClockTriggerDate"
            error={
              errors.timeClockTriggerDate
                ? { message: errors.timeClockTriggerDate.message || 'This field is required' }
                : null
            }
            label={`${trigger} (MM/DD/YYYY)`}
            onChange={(date) => onChange(dayjs(date).format('YYYY-MM-DD'))}
            show={showDatePicker}
            setShow={setShowDatePicker}
            value={value ? dayjs(value).format('YYYY-MM-DD') : null}
          />
        )}
        rules={{
          required: true,
        }}
      />
    )
  }

  useEffect(() => {
    if (repairOrder) {
      reset(repairOrder)
    }
  }, [repairOrder])

  useEffect(() => {
    if (advisorQuery.length >= 2) {
      const lowerCaseQuery = advisorQuery.toLowerCase()

      setFilteredAdvisors(
        allAdvisors.filter((person) => person.toLowerCase().includes(lowerCaseQuery)),
      )
    } else {
      setFilteredAdvisors([])
    }
  }, [advisorQuery, allAdvisors])

  return (
    <Modal
      open={isOpen}
      title="Edit Repair Order"
      setOpen={setIsOpen}
      loading={loading}
      onClose={() => closeModal()}
      content={
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <TextInput
            data-testid="roNumber"
            error={errors.roNumber && 'This field is required'}
            label="REPAIR ORDER #"
            inputStyles="uppercase"
            id="roNumber"
            name="roNumber"
            placeholder=""
            required
            fullWidth
            {...register('roNumber', { required: true })}
          />

          <Controller
            name="advisor"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field: { onChange, value } }) => (
              <AutoCompleteCombobox
                id="advisor-id"
                dataTestId="advisor"
                label="ADV #"
                value={value}
                containerClassName="mt-[20px]"
                inputClassName="font-medium text-black"
                onComboboxChange={(fieldValue) => {
                  setValue('advisor', fieldValue)
                  clearErrors('advisor')
                }}
                options={filteredAdvisors}
                errorMessage={errors.advisor?.message}
                onComboboxInputChange={(event) => {
                  const inputValue = event.target.value
                  setAdvisorQuery(inputValue)
                  setValue('advisor', inputValue, { shouldValidate: true })
                  clearErrors('advisor')
                }}
                onChange={onChange}
              />
            )}
          />

          {/* Open Status */}
          <Controller
            name="isOpen"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Toggle label="Open RO?" className="mb-8" checked={value} onChange={onChange} />
            )}
          />

          {/* Age Out Override */}
          <Controller
            name="isAgesOutOverridden"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Toggle
                label="Requires Age Out Override"
                className="mb-8"
                checked={value}
                onChange={onChange}
              />
            )}
          />

          {renderRepairOrderDateField()}
        </form>
      }
      actions={[
        {
          type: 'submit',
          label: 'Save',
          onClick: handleSubmit(handleFormSubmit),
        },
        { type: 'cancel', label: 'Cancel', onClick: () => closeModal() },
      ]}
    />
  )
}

export default RepairOrderModal

RepairOrderModal.defaultValues = {
  defaultTechnician: '',
}

RepairOrderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  getUpdatedRepairOrder: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  repairOrder: PropTypes.shape({
    roNumber: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    advisor: PropTypes.string.isRequired,
    timeClockTriggerDate: PropTypes.string,
    isAgesOutOverridden: PropTypes.bool,
    isOpen: PropTypes.bool,
    isDateAddedOverridden: PropTypes.bool,
    agesOut: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
  dealer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    manufacturer: PropTypes.shape({
      timeClockTrigger: PropTypes.string,
    }).isRequired,
  }).isRequired,
  allAdvisors: PropTypes.arrayOf(PropTypes.string).isRequired,
}
