/* eslint-disable react/no-unstable-nested-components */
import {
  DragDropContext,
  Draggable,
  Droppable,
} from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { Button } from '../../components/Button'
import { CheckBox } from '../../components/CheckBox'
import { DataTable } from '../../components/DataTable'
import { Highlights } from '../../components/Highlights'
import { Modal } from '../../components/Modal'
import { MultiSelectDropdown } from '../../components/MultiSelectDropdown'
import { Pagination } from '../../components/Pagination'
import { Select } from '../../components/Select'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'
import CollapsibleSection from './CollapsibleSection'
import RepairOrderCard from './RepairOrderCard'

// Icons
import { AddIcon } from '../../components/AddIcon'
import { AddressCardIcon } from '../../components/AddressCardIcon'
import { DownloadIcon } from '../../components/DownloadIcon'
import { FilterIcon } from '../../components/FilterIcon'
import { MinusIcon } from '../../components/MinusIcon'
import { SearchIcon } from '../../components/SearchIcon'
import { SquaresIcon } from '../../components/SquaresIcon'
import { TableIcon } from '../../components/TableIcon'

// Store
import { DisplayStoreContext } from '../../stores/DisplayStore'
import { TaskStoreContext } from '../../stores/TaskStore'
import { UserStoreContext } from '../../stores/UserStore'

// Utils & Service
import {
  createRepairOrderExport,
  getActionsNeeded,
  getAdvisors,
  getDealer,
  getRepairOrderExport,
  getRepairOrderKpis,
  getRepairOrders,
  getTechnicians,
} from '../../services/dealer.service'
import { getDealers } from '../../services/dealers.service'
import { updateRepairOrder } from '../../services/repairOrders.service'
import { formatPhoneNumber, handlePagination, toast } from '../../utils/helpers'
import {
  CHECKBOX_FILTERS,
  CHECKBOX_FILTERS_WITH_DATES,
  COLUMNS,
  DEFAULT_FILTERS,
  RESOLVED_COLUMNS,
} from './constants'

const DealerDetails = observer(() => {
  // Context
  const navigate = useNavigate()
  const { id } = useParams()
  const { createNewTask } = useContext(TaskStoreContext)
  const { isDealerUser } = useContext(UserStoreContext)
  const { activeView, setActiveView } = useContext(DisplayStoreContext)

  // State
  const [firstLoad, setFirstLoad] = useState(true)
  const [loadingOutstanding, setLoadingOutstanding] = useState(true)
  const [loadingResolved, setLoadingResolved] = useState(true)
  const [error, setError] = useState(null)
  const [updatedDealers, setUpdatedDealers] = useState([])
  const [updatedDealer, setUpdatedDealer] = useState({})
  const [kpis, setKpis] = useState([])
  const [search, setSearch] = useState(null)
  const [searchTerm, setSearchTerm] = useState(null)
  const [loadingExport, setLoadingExport] = useState(false)
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false)
  const [repairOrderPayload, setRepairOrderPayload] = useState(false)
  const [selectedRepairOrderId, setSelectedRepairOrderId] = useState(false)

  // Filters
  const [actionsNeeded, setActionsNeeded] = useState([])
  const [advisors, setAdvisors] = useState([])
  const [technicians, setTechnicians] = useState([])
  const [selectedFilters, setSelectedFilters] = useState(DEFAULT_FILTERS)

  // WPC Response
  const [wpcRepairOrders, setWpcRepairOrders] = useState([])
  const [wpcCurrentPage, setWpcCurrentPage] = useState(1)
  const [wpcPerPage, setWpcPerPage] = useState(20)
  const [wpcTotalRows, setWpcTotalRows] = useState()
  const [wpcPages, setWpcPages] = useState(null)
  const [wpcSortedColumn, setWpcSortedColumn] = useState('ro_number')

  // Dealer Response
  const [dealerRepairOrders, setDealerRepairOrders] = useState([])
  const [dealerCurrentPage, setDealerCurrentPage] = useState(1)
  const [dealerPerPage, setDealerPerPage] = useState(20)
  const [dealerTotalRows, setDealerTotalRows] = useState()
  const [dealerPages, setDealerPages] = useState(null)
  const [dealerSortedColumn, setDealerSortedColumn] = useState('ro_number')

  // Resolved Repair Orders Response
  const [resolvedRepairOrders, setResolvedRepairOrders] = useState([])
  const [resolvedCurrentPage, setResolvedCurrentPage] = useState(1)
  const [resolvedPerPage, setResolvedPerPage] = useState(20)
  const [resolvedTotalRows, setResolvedTotalRows] = useState()
  const [resolvedPages, setResolvedPages] = useState(null)
  const [resolvedSortedColumn, setResolvedSortedColumn] = useState('resolved_at')

  // Resolved Search Filters
  const [resolvedSearchTerm, setResolvedSearchTerm] = useState('')
  const [resolvedSearch, setResolvedSearch] = useState(null)
  const [selectedResolvedFilters, setSelectedResolvedFilters] = useState(DEFAULT_FILTERS)

  // Ref
  const searchInputRef = useRef()
  const resolvedSearchInputRef = useRef()

  const handleErrors = (m) => toast(m, 'error')
  const handleSuccess = (message) => toast(message, 'success')

  const DEALER_BASE_URL = `/dealers/${id}/repair-orders/?expand=line_items,line_items.actions_needed,line_items.actions_needed.action`
  const KPI_BASE_URL = `/dealers/${id}/repair-orders/kpis?state=Dealer Review&state=WPC Review`

  /**
   * Fetches the repair orders based on the specified `url` and `state`.
   * @param {string} url
   * @param {string} state
   */
  const getUpdatedRepairOrders = async (url, state = '') => {
    let limit = ''
    if (state === 'WPC Review') {
      limit = wpcPerPage
    } else if (state === 'Dealer Review') {
      limit = dealerPerPage
    } else if (state === 'Resolved') {
      limit = resolvedPerPage
    }

    const response = await getRepairOrders(
      `${url}&state=${state}&limit=${limit}`,
      state === 'Resolved' ? setLoadingResolved : setLoadingOutstanding,
      setError,
    )

    if (response) {
      if (state === 'WPC Review') {
        setWpcRepairOrders(response.results)
        setWpcTotalRows(response.count)
        setWpcPages({ next: response.next, previous: response.previous })
      } else if (state === 'Dealer Review') {
        setDealerRepairOrders(response.results)
        setDealerTotalRows(response.count)
        setDealerPages({ next: response.next, previous: response.previous })
      } else if (state === 'Resolved') {
        setResolvedRepairOrders(response.results)
        setResolvedTotalRows(response.count)
        setResolvedPages({ next: response.next, previous: response.previous })
      }
    }
  }

  /**
   * Configures the filter query, without the specified `without` value.
   * @param {string} without
   */
  const configureFilterQuery = (filters, without = null, resolved = false) => {
    const filterQuery = { ...filters }

    filterQuery.line_items__technician = filterQuery.technician
    filterQuery.line_items__actions_needed__action = filterQuery.actionNeeded
    delete filterQuery.actionNeeded
    delete filterQuery.technician

    if (without) delete filterQuery[without]

    return _.map(filterQuery, (value, key) => {
      if (!value || value.length === 0) return null
      if (key === 'highDollarResolved' && resolved) return CHECKBOX_FILTERS_WITH_DATES[key]
      if (CHECKBOX_FILTERS.includes(key)) return CHECKBOX_FILTERS_WITH_DATES[key]
      return `${key}=${_.map(value, 'id').join(`&${key}=`)}`
    })
      .filter((x) => x !== null)
      .join('&')
  }

  /**
   * Gets the updated repair order KPIs.
   * @param {string} url
   */
  const getUpdatedRepairOrderKpis = async (url) => {
    const response = await getRepairOrderKpis(url, () => {}, handleErrors)

    if (response) {
      // Transform histogram into the format we need
      const histogram = _.map(response.roAgeHistogram, (value, key) => ({
        days: key,
        age: value,
      }))

      setKpis({
        ...response,
        roAgeHistogram: histogram,
      })
    }
  }

  useEffect(() => {
    setError(null)

    const getUpdatedData = async () => {
      const [dealers, dealer, actions, adv, tech] = await Promise.all([
        getDealers('/dealers?limit=99999', () => {}, setError),
        getDealer(id, () => {}, setError),
        getActionsNeeded(id, () => {}, handleErrors),
        getAdvisors(id, () => {}, handleErrors),
        getTechnicians(id, () => {}, handleErrors),
      ])

      setUpdatedDealers(_.map(dealers.results, (d) => ({ id: d.id, label: d.name })))
      setUpdatedDealer(dealer)
      setActionsNeeded(_.map(actions, (a) => ({ id: a.id, label: a.name })))
      setAdvisors(_.map(adv, (a) => ({ id: a, label: a })))
      setTechnicians(_.map(tech, (t) => ({ id: t, label: t })))

      setFirstLoad(false)
    }

    getUpdatedData()
  }, [id])

  /**
   * Reusable function to configure URL parameters based on search term and filters.
   * @param {string} specifiedSearchTerm - The search term to be used in the query.
   * @param {Array} filters - The selected filters for the query.
   */
  const configureUrlParams = (specifiedSearchTerm, filters, resolved) => {
    let params = ''
    const filterQuery = configureFilterQuery(filters, null, resolved)

    if (specifiedSearchTerm) {
      params = `q=${specifiedSearchTerm}`
    }
    if (filterQuery !== '' && filterQuery !== '&') {
      params = `${params === '' ? '' : `${params}&`}${filterQuery}`
    }

    return params === '' ? '' : params
  }

  /**
   * Configure the URL parameters based on the search term and filters.
   */
  const urlParams = useMemo(
    () => configureUrlParams(search, selectedFilters),
    [search, selectedFilters],
  )

  /**
   * Configure the URL Resolved Repair Order parameters based on the search term and filters.
   */
  const urlParamsResolvedRepairOrders = useMemo(
    () => configureUrlParams(resolvedSearch, selectedResolvedFilters, true),
    [resolvedSearch, selectedResolvedFilters],
  )

  /**
   * Gets updated kpis when url params change or the dealer id.
   */
  useEffect(() => {
    getUpdatedRepairOrderKpis(`${KPI_BASE_URL}&${urlParams}`)
  }, [id, urlParams])

  /**
   * Gets updated repair orders that are in WPC review.
   */
  useEffect(() => {
    const params = urlParams === '' ? '' : `&${urlParams}`
    getUpdatedRepairOrders(
      `${DEALER_BASE_URL}&limit=${wpcPerPage}&page=1&order_by=${wpcSortedColumn}${params}`,
      'WPC Review',
    )
  }, [id, urlParams, wpcPerPage, wpcSortedColumn])

  /**
   * Gets updated repair orders that are in Dealer review.
   */
  useEffect(() => {
    const params = urlParams === '' ? '' : `&${urlParams}`
    getUpdatedRepairOrders(
      `${DEALER_BASE_URL}&limit=${dealerPerPage}&page=1&order_by=${dealerSortedColumn}${params}`,
      'Dealer Review',
    )
  }, [id, urlParams, dealerPerPage, dealerSortedColumn])

  /**
   * Gets updated repair orders that are resolved.
   */
  useEffect(() => {
    const params = urlParamsResolvedRepairOrders === '' ? '' : `&${urlParamsResolvedRepairOrders}`
    getUpdatedRepairOrders(
      `${DEALER_BASE_URL}&limit=${resolvedPerPage}&page=1&order_by=${resolvedSortedColumn}${params}`,
      'Resolved',
    )
  }, [id, urlParamsResolvedRepairOrders, resolvedPerPage, resolvedSortedColumn])

  /**
   * Debounces the search input.
   */
  const searchRepairOrders = useCallback(_.debounce(setSearch, 500), [])

  /**
   * Debounces the resolved search input.
   */
  const searchResolvedRepairOrders = useCallback(_.debounce(setResolvedSearch, 500), [])

  /**
   * Configures the address based on the updated dealer.
   */
  const configureAddress = () => {
    let address = ''
    if (updatedDealer.city) {
      address += `${updatedDealer.city}, `
    }
    if (updatedDealer.state) {
      address += `${updatedDealer.state} `
    }
    if (updatedDealer.postalCode) {
      address += updatedDealer.postalCode
    }

    return address
  }

  /**
   * Renders the expanded row with the specified repair order `data`.
   * @param {object} props
   * @returns
   */
  const renderExpandableComponent = ({ data }, showTechnician = true) => {
    const lineItemActionsNeeded = _.map(data.lineItems, (l) => _.map(l.actionsNeeded, (an) => an))
    const actions = _.map(
      _.orderBy(
        _.map(_.flatten(lineItemActionsNeeded), (actionNeeded) => ({
          action: actionNeeded.action,
          completed: actionNeeded.completed,
        })),
        'completed',
        'desc',
      ),
      (a) => a.action.name,
    )

    return (
      <div className="bg-white-dark flex flex-row px-3 py-2">
        <div className="flex w-[195px] shrink-0 pl-[50px] align-top">
          <span className="text-sm italic">Additional Details</span>
        </div>
        {showTechnician && (
          <div className="flex w-[190px] shrink-0 flex-col px-2">
            <span className="text-charcoal-400 text-xs font-bold">TECH #</span>
            <span className="text-charcoal-900 text-sm">
              {_.uniq(_.map(data.lineItems, (l) => l.technician)).join(', ')}
            </span>
          </div>
        )}

        {showTechnician && (
          <div className=" flex w-[170px] shrink-0 flex-col px-2">
            <span className="text-charcoal-400 text-xs font-bold">ADV ID</span>
            <span className="text-charcoal-900 text-sm">{data.advisor}</span>
          </div>
        )}

        <div className="flex w-[250px] shrink-0 flex-col px-2">
          <span className="text-charcoal-400 text-xs font-bold">ACTION DETAILS</span>
          <div className="text-charcoal-900 flex flex-col text-sm">
            {_.map(actions, (i) => (
              <span>• {i}</span>
            ))}
          </div>
        </div>

        <div className=" flex w-full flex-col px-2">
          <span className="text-charcoal-400 text-xs font-bold">PROBLEM DETAILS</span>
          <span className="text-charcoal-900 text-sm">
            {_.map(data.lineItems, (l) => l.problem).join(' ')}
          </span>
        </div>
      </div>
    )
  }

  /**
   * Renders the dealer datatable.
   */
  const renderWaitingOnDealerResponseTable = () => (
    <DataTable
      onRowClicked={(row) => navigate(`/dealer/${row.dealer}/repair-order/${row.id}`)}
      highlightOnHover
      columns={COLUMNS}
      data={dealerRepairOrders}
      expandableIcon={{
        collapsed: (
          <div className="flex flex-row gap-2">
            <div className="bg-blue-dark h-[38px] w-2 rounded-r-[4px]" />
            <AddIcon className="h-4 stroke-blue-800" />
          </div>
        ),
        expanded: (
          <div className="flex flex-row gap-3">
            <div className="bg-blue-dark h-[38px] w-2 rounded-r-[4px]" />
            <MinusIcon className="h-4 stroke-blue-800" />
          </div>
        ),
      }}
      expandableRows
      expandableRowsComponent={renderExpandableComponent}
      onChangePage={(page) =>
        handlePagination(
          page,
          dealerCurrentPage,
          dealerPerPage,
          dealerTotalRows,
          dealerPages,
          setDealerCurrentPage,
          (url) => getUpdatedRepairOrders(url, 'Dealer Review'),
          DEALER_BASE_URL,
          `order_by=${dealerSortedColumn}`,
        )
      }
      onChangeRowsPerPage={(currentRowsPerPage) => setDealerPerPage(currentRowsPerPage)}
      onSort={(column, direction) => {
        const d = direction === 'asc' ? '' : '-'
        setDealerSortedColumn(`${d}${column.sortBy}`)
      }}
      pagination
      paginationServer
      paginationPerPage={dealerPerPage}
      paginationRowsPerPageOptions={[10, 20, 30, 50]}
      paginationTotalRows={dealerTotalRows}
      progressPending={loadingOutstanding}
      sortServer
    />
  )

  /**
   * Renders the WPC datatable.
   */
  const renderWaitingOnWpcResponseTable = () => (
    <DataTable
      onRowClicked={(row) => navigate(`/dealer/${row.dealer}/repair-order/${row.id}`)}
      highlightOnHover
      columns={COLUMNS}
      data={wpcRepairOrders}
      expandableIcon={{
        collapsed: (
          <div className="flex flex-row gap-2">
            <div className="bg-gold-dark h-[38px] w-2 rounded-r-[4px]" />
            <AddIcon className="h-4 stroke-blue-800" />
          </div>
        ),
        expanded: (
          <div className="flex flex-row gap-3">
            <div className="bg-gold-dark h-[38px] w-2 rounded-r-[4px]" />
            <MinusIcon className="h-4 stroke-blue-800" />
          </div>
        ),
      }}
      expandableRows
      expandableRowsComponent={renderExpandableComponent}
      onChangePage={(page) =>
        handlePagination(
          page,
          wpcCurrentPage,
          wpcPerPage,
          wpcTotalRows,
          wpcPages,
          setWpcCurrentPage,
          (url) => getUpdatedRepairOrders(url, 'WPC Review'),
          DEALER_BASE_URL,
          `order_by=${wpcSortedColumn}`,
        )
      }
      onChangeRowsPerPage={(currentRowsPerPage) => setWpcPerPage(currentRowsPerPage)}
      onSort={(column, direction) => {
        const d = direction === 'asc' ? '' : '-'
        setWpcSortedColumn(`${d}${column.sortBy}`)
      }}
      pagination
      paginationServer
      paginationPerPage={wpcPerPage}
      paginationRowsPerPageOptions={[10, 20, 30, 50]}
      paginationTotalRows={wpcTotalRows}
      progressPending={loadingOutstanding}
      sortServer
    />
  )

  /**
   * Handles pagination for the dealer review repair orders.
   * @param {number} page
   */
  const handleDealerReviewPagination = (page) => {
    handlePagination(
      page,
      dealerCurrentPage,
      dealerPerPage,
      dealerTotalRows,
      dealerPages,
      setDealerCurrentPage,
      (url) => getUpdatedRepairOrders(url, 'Dealer Review'),
      DEALER_BASE_URL,
      `order_by=${dealerSortedColumn}`,
    )
  }

  /**
   * Handles pagination for the WPC review repair orders.
   * @param {number} page
   */
  const handleWpcReviewPagination = (page) => {
    handlePagination(
      page,
      wpcCurrentPage,
      wpcPerPage,
      wpcTotalRows,
      wpcPages,
      setWpcCurrentPage,
      (url) => getUpdatedRepairOrders(url, 'WPC Review'),
      DEALER_BASE_URL,
      `order_by=${wpcSortedColumn}`,
    )
  }

  /**
   * Handles updating repair order status once the user has finished dragging the item.
   * @param {object} result
   * @returns
   */
  const onDragEnd = async ({ source, destination, draggableId }) => {
    // Check if the drag resulted in a valid drop (not outside of any droppable) and if the user is a dealer user
    if (!destination || isDealerUser) {
      return
    }

    // Determine the movement direction and set payload accordingly
    let payload = {}
    if (source.droppableId === 'columnA' && destination.droppableId === 'columnB') {
      // Item moved from column A to column B
      payload = { markAsDealerReview: true }
    } else if (source.droppableId === 'columnB' && destination.droppableId === 'columnA') {
      // Item moved from column B to column A
      payload = { markAsWpcReview: true }
    } else {
      // If the item is not moving between the specified columns, do nothing
      return
    }

    setRepairOrderPayload(payload)
    setSelectedRepairOrderId(draggableId)
    setIsConfirmationModalVisible(true)
  }

  /**
   * Renders a subset of cards based on whether the index is even or odd.
   *
   * @param {Array} data - The array of data items to map through.
   * @param {boolean} isEven - True to filter even indices, false for odd indices.
   * @param {boolean} requiresReview - Whether to include the 'requiresReview' prop.
   * @returns JSX elements of filtered cards.
   */
  const renderFilteredCards = (data, isEven, requiresReview = false, isClickable = false) =>
    _.map(
      data,
      (item, index) =>
        (isEven ? index % 2 === 0 : index % 2 !== 0) && (
          <Draggable
            draggableId={item.id}
            key={item.id}
            index={index}
            isDragDisabled={isDealerUser}
          >
            {(provided) => (
              <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{
                  ...provided.draggableProps.style,
                  cursor: isDealerUser ? 'default' : 'grab',
                }}
                ref={provided.innerRef}
              >
                <RepairOrderCard
                  key={`column-${isEven ? 'even' : 'odd'}-${item.id}`}
                  item={item}
                  requiresReview={requiresReview}
                  isClickable={isClickable}
                />
              </div>
            )}
          </Draggable>
        ),
    )

  const renderRequiresReviewCards = () => {
    if (
      (isDealerUser && dealerRepairOrders.length === 0) ||
      (!isDealerUser && wpcRepairOrders.length === 0)
    ) {
      return (
        <div className="flex h-full w-full flex-col items-center justify-center">
          <span className="text-charcoal-400 text-lg">No repair orders found.</span>
        </div>
      )
    }

    return (
      <>
        <div className="w-full max-w-[380px] lg:w-1/2">
          {isDealerUser
            ? renderFilteredCards(dealerRepairOrders, true, true, true)
            : renderFilteredCards(wpcRepairOrders, true, true, true)}
        </div>

        <div className="w-full max-w-[380px] lg:w-1/2">
          {isDealerUser
            ? renderFilteredCards(dealerRepairOrders, false, true, true)
            : renderFilteredCards(wpcRepairOrders, false, true, true)}
        </div>
      </>
    )
  }

  const renderWaitingOnResponseCards = () => {
    if (
      (isDealerUser && wpcRepairOrders.length === 0) ||
      (!isDealerUser && dealerRepairOrders.length === 0)
    ) {
      return (
        <div className="flex h-full w-full items-center justify-center">
          <span className="text-charcoal-400 text-lg">No repair orders found.</span>
        </div>
      )
    }

    return (
      <>
        <div className="w-full max-w-[380px] lg:w-1/2">
          {isDealerUser
            ? renderFilteredCards(wpcRepairOrders, true, false)
            : renderFilteredCards(dealerRepairOrders, true, false)}
        </div>

        <div className="w-full max-w-[380px] lg:w-1/2">
          {isDealerUser
            ? renderFilteredCards(wpcRepairOrders, false, false)
            : renderFilteredCards(dealerRepairOrders, false, false)}
        </div>
      </>
    )
  }

  const validDetails =
    updatedDealer &&
    (updatedDealer.address ||
      updatedDealer.address2 ||
      !!configureAddress() ||
      updatedDealer.phoneNumber)

  const activeFilters = _.values(selectedFilters).some((o) => o.length > 0 || o === true)
  const activeResolvedFilters = _.values(selectedResolvedFilters).some(
    (o) => o.length > 0 || o === true,
  )

  return (
    <div
      className={mergeClassNames(
        'bg-background flex w-full flex-col pb-12',
        firstLoad && 'h-full',
      )}
    >
      <StateContainer error={error} loading={firstLoad}>
        <div className="flex w-full flex-col space-y-4 overflow-y-auto px-2 pb-12 pt-6 sm:px-8 lg:px-10">
          <div className="flex h-auto w-full flex-col justify-between md:flex-row">
            <div>
              <Select
                className="border-none bg-transparent pl-0 shadow-none"
                options={updatedDealers}
                value={_.find(updatedDealers, { id: updatedDealer.id })}
                onChange={(o) => navigate(`/dealer/${o.id}/detail`)}
              />
            </div>

            {validDetails && (
              <div className="flex flex-row items-start gap-2">
                <AddressCardIcon className="w-10" />

                <div className="text-charcoal-900 flex flex-col items-end text-sm font-medium">
                  <span>{updatedDealer && updatedDealer.address}</span>
                  <span>{updatedDealer && updatedDealer.address2}</span>
                  <span>{updatedDealer && configureAddress()}</span>
                  <span>{updatedDealer && formatPhoneNumber(updatedDealer.phoneNumber)}</span>
                </div>
              </div>
            )}
          </div>

          {/* Outstanding List Highlights */}
          <CollapsibleSection title="Outstanding List Highlights">
            <Highlights kpis={kpis} />
          </CollapsibleSection>

          {/* Outstanding List ROs */}
          <CollapsibleSection title="Outstanding List ROs">
            <div className="text-gray-500">
              <div className="mb-4 flex flex-wrap items-center justify-between gap-4">
                <div className="flex items-start gap-x-4">
                  <TextInput
                    className="w-72 pl-10"
                    data-testid="repair-search"
                    icon={<SearchIcon className="stroke-charcoal ml-2 w-5" />}
                    endIcon={
                      searchTerm ? (
                        <button
                          type="button"
                          onClick={() => {
                            setSearchTerm('')
                            searchRepairOrders('')
                            searchInputRef.current.value = ''
                          }}
                        >
                          <XMarkIcon className="text-midnight h-5" aria-hidden="true" />
                        </button>
                      ) : null
                    }
                    onChange={(e) => {
                      searchRepairOrders(e.target.value)
                      setSearchTerm(e.target.value)
                    }}
                    placeholder="Search"
                    value={searchTerm}
                    hideError
                    ref={searchInputRef}
                  />

                  <div className="mt-1 flex flex-wrap items-center gap-2">
                    <Popover className="relative">
                      <PopoverButton
                        className={mergeClassNames(
                          'bg-background border-brownGray-200 hover:bg-charchoal-light hover:border-brownGray-300 rounded-lg border-2 p-3 outline-none ring-0',
                          activeFilters && 'border-blue-800 hover:border-blue-900',
                        )}
                        data-testid="filter"
                        aria-label="Filter Repair Orders"
                      >
                        <FilterIcon
                          className={mergeClassNames(
                            'fill-charcoal-400 h-4 w-4',
                            activeFilters && 'fill-blue-800',
                          )}
                        />
                      </PopoverButton>

                      <PopoverPanel
                        anchor="bottom end"
                        className="z-10 flex w-[420px] flex-col gap-2 !overflow-visible rounded-lg bg-[#ffffff] p-3 shadow-md"
                      >
                        <span className="text-md font-medium">Filters</span>

                        <div className="flex w-full flex-row">
                          <div className="flex w-1/2 flex-col gap-1">
                            <CheckBox
                              label="Ages out in < 7 days"
                              name="agesOut"
                              onChange={() =>
                                setSelectedFilters({
                                  ...selectedFilters,
                                  agesOut: !selectedFilters.agesOut,
                                })
                              }
                              value={selectedFilters.agesOut}
                            />

                            <CheckBox
                              label="No update in > 5 days"
                              name="noUpdate"
                              onChange={() =>
                                setSelectedFilters({
                                  ...selectedFilters,
                                  noUpdate: !selectedFilters.noUpdate,
                                })
                              }
                              value={selectedFilters.noUpdate}
                            />

                            <CheckBox
                              label="High dollar (> $10,000)"
                              name="highDollar"
                              onChange={() =>
                                setSelectedFilters({
                                  ...selectedFilters,
                                  highDollar: !selectedFilters.highDollar,
                                })
                              }
                              value={selectedFilters.highDollar}
                            />
                          </div>

                          <div className="flex w-1/2 flex-col gap-1">
                            <CheckBox
                              label="New in last 7 days"
                              name="new"
                              onChange={() =>
                                setSelectedFilters({
                                  ...selectedFilters,
                                  new: !selectedFilters.new,
                                })
                              }
                              value={selectedFilters.new}
                            />
                            <CheckBox
                              label="Open RO"
                              name="open"
                              onChange={() =>
                                setSelectedFilters({
                                  ...selectedFilters,
                                  isOpen: !selectedFilters.isOpen,
                                })
                              }
                              value={selectedFilters.isOpen}
                            />
                            <CheckBox
                              label="Closed RO"
                              name="closed"
                              onChange={() =>
                                setSelectedFilters({
                                  ...selectedFilters,
                                  isClosed: !selectedFilters.isClosed,
                                })
                              }
                              value={selectedFilters.isClosed}
                            />
                          </div>
                        </div>

                        <MultiSelectDropdown
                          className="bg-[#ffffff]"
                          id="action-needed"
                          label="Action Needed"
                          listBoxClassName="bg-[#ffffff]"
                          onChange={(o) =>
                            setSelectedFilters({ ...selectedFilters, actionNeeded: o })
                          }
                          options={actionsNeeded}
                          value={selectedFilters.actionNeeded}
                        />

                        <MultiSelectDropdown
                          className="bg-[#ffffff]"
                          id="advisor"
                          label="Advisor Number"
                          listBoxClassName="bg-[#ffffff]"
                          onChange={(o) => setSelectedFilters({ ...selectedFilters, advisor: o })}
                          options={advisors}
                          value={selectedFilters.advisor}
                        />

                        <MultiSelectDropdown
                          className="bg-[#ffffff]"
                          id="technician"
                          label="Technician"
                          listBoxClassName="bg-[#ffffff]"
                          onChange={(o) =>
                            setSelectedFilters({ ...selectedFilters, technician: o })
                          }
                          openAbove
                          options={technicians}
                          value={selectedFilters.technician}
                        />
                      </PopoverPanel>
                    </Popover>
                    {activeFilters && (
                      <button
                        className="cursor-pointer rounded-md text-sm font-semibold leading-5 tracking-[0.25px] text-blue-800 hover:text-blue-900"
                        onClick={() => setSelectedFilters(DEFAULT_FILTERS)}
                        type="button"
                      >
                        Reset Filters
                      </button>
                    )}
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex flex-row gap-2">
                    {!isDealerUser && (
                      <Button
                        className="px-1 py-2"
                        dataTestId="create-repair-order"
                        label={<AddIcon className="h-4 stroke-[#ffffff]" />}
                        onClick={() => navigate(`/dealer/${id}/repair-order/create`)}
                        ariaLabel="Create New Repair Order"
                      />
                    )}

                    <Button
                      className="px-1 py-2"
                      dataTestId="export-repair-orders"
                      disabled={loadingExport}
                      label={<DownloadIcon className="h-4 stroke-[#ffffff]" />}
                      ariaLabel="Export Unresolved Repair Orders"
                      onClick={async () => {
                        const baseParams = '?state=WPC Review&state=Dealer Review'
                        const params = urlParams === '' ? baseParams : `${baseParams}&${urlParams}`
                        const exportTask = await createRepairOrderExport(
                          `/dealers/${id}/repair-order-exports/${params}`,
                          handleErrors,
                          setLoadingExport,
                        )

                        if (exportTask) {
                          createNewTask('Download', exportTask, {
                            label: 'Repair Orders',
                            filename: `${updatedDealer.name} - Repair Orders.xlsx`,
                            checkUpdatedTask: async () => {
                              const updatedTask = await getRepairOrderExport(
                                id,
                                exportTask.id,
                                handleErrors,
                              )
                              return updatedTask
                            },
                          })
                        }
                      }}
                    />
                  </div>
                  <div
                    role="group"
                    aria-label="View options"
                    className="ml-4 flex items-center gap-2 rounded-xl bg-blue-800/10 p-1"
                  >
                    <button
                      type="button"
                      aria-pressed={activeView === 'grid'}
                      aria-label="Grid view"
                      className={`rounded-lg p-2 ${
                        activeView === 'grid' ? 'bg-white shadow-md' : 'bg-transparent'
                      }`}
                      onClick={() => {
                        setWpcSortedColumn('ages_out')
                        setDealerSortedColumn('ages_out')
                        setActiveView('grid')
                      }}
                    >
                      <SquaresIcon className="h-4 w-4 text-blue-800" />
                    </button>
                    <button
                      type="button"
                      aria-pressed={activeView === 'table'}
                      aria-label="Table view"
                      className={`rounded-lg p-2 ${
                        activeView === 'table' ? 'bg-white shadow-md' : 'bg-transparent'
                      }`}
                      onClick={() => setActiveView('table')}
                    >
                      <TableIcon className="h-4 w-4 text-blue-800" />
                    </button>
                  </div>
                </div>
              </div>

              {activeView === 'grid' ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <div className="flex flex-col sm:flex-row">
                    <div className="flex w-full max-w-[800px] flex-col pl-4 pr-6 sm:w-1/2">
                      {/* Requires Review */}
                      <h2 className="text-gold-dark text-md text-center font-bold">
                        Requires Review
                      </h2>

                      <Droppable droppableId="columnA">
                        {(provided, snapshot) => (
                          <div
                            className={`flex min-h-[400px] flex-col items-center gap-x-4 lg:flex-row lg:items-start ${
                              snapshot.isDraggingOver ? 'rounded-md bg-blue-300/80' : ''
                            }`}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {renderRequiresReviewCards()}

                            <div className="h-px overflow-hidden">{provided.placeholder}</div>
                          </div>
                        )}
                      </Droppable>

                      {isDealerUser ? (
                        <Pagination
                          totalItems={dealerTotalRows}
                          itemsPerPage={dealerPerPage}
                          currentPage={dealerCurrentPage}
                          setCurrentPage={setDealerCurrentPage}
                          onPageChange={handleDealerReviewPagination}
                        />
                      ) : (
                        <Pagination
                          totalItems={wpcTotalRows}
                          itemsPerPage={wpcPerPage}
                          currentPage={wpcCurrentPage}
                          setCurrentPage={setWpcCurrentPage}
                          onPageChange={handleWpcReviewPagination}
                        />
                      )}
                    </div>

                    <div className="flex w-full max-w-[800px] flex-col pl-6 sm:w-1/2">
                      {/* Waiting on Response */}
                      <h2 className="text-md text-center font-bold text-blue-950">
                        Waiting on {isDealerUser ? 'WPC Feedback' : 'Dealer Response'}
                      </h2>

                      <Droppable droppableId="columnB">
                        {(provided, snapshot) => (
                          <div
                            className={mergeClassNames(
                              `flex min-h-[400px] flex-col items-center gap-x-4 lg:flex-row lg:items-start ${
                                snapshot.isDraggingOver ? 'rounded-md bg-blue-300/80' : ''
                              }`,
                            )}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {renderWaitingOnResponseCards()}

                            <div className="h-px overflow-hidden">{provided.placeholder}</div>
                          </div>
                        )}
                      </Droppable>

                      {isDealerUser ? (
                        <Pagination
                          totalItems={wpcTotalRows}
                          itemsPerPage={wpcPerPage}
                          currentPage={wpcCurrentPage}
                          setCurrentPage={setWpcCurrentPage}
                          onPageChange={handleWpcReviewPagination}
                        />
                      ) : (
                        <Pagination
                          totalItems={dealerTotalRows}
                          itemsPerPage={dealerPerPage}
                          currentPage={dealerCurrentPage}
                          setCurrentPage={setDealerCurrentPage}
                          onPageChange={handleDealerReviewPagination}
                        />
                      )}
                    </div>
                  </div>
                </DragDropContext>
              ) : (
                <div className="space-y-4">
                  {/* Requires Review */}
                  <div className="flex flex-col gap-2">
                    <span className="text-gold-dark text-md font-bold">Requires Review</span>

                    {isDealerUser
                      ? renderWaitingOnDealerResponseTable()
                      : renderWaitingOnWpcResponseTable()}
                  </div>

                  {/* Waiting on Response */}
                  <div className="flex flex-col gap-2">
                    <span className="text-md font-bold text-blue-950">
                      Waiting on {isDealerUser ? 'WPC Feedback' : 'Dealer Response'}
                    </span>

                    {isDealerUser
                      ? renderWaitingOnWpcResponseTable()
                      : renderWaitingOnDealerResponseTable()}
                  </div>
                </div>
              )}
            </div>
          </CollapsibleSection>

          {/* Resolved Repair Orders */}
          <CollapsibleSection title="Resolved ROs" defaultOpen={false}>
            <div className="mb-4 flex flex-wrap items-center justify-between gap-4">
              <div className="flex items-start gap-x-4">
                <TextInput
                  className="w-72 pl-10"
                  data-testid="resolved-repair-search"
                  icon={<SearchIcon className="stroke-charcoal ml-2 w-5" />}
                  endIcon={
                    resolvedSearchTerm ? (
                      <button
                        type="button"
                        onClick={() => {
                          setResolvedSearchTerm('')
                          searchResolvedRepairOrders('')
                          resolvedSearchInputRef.current.value = ''
                        }}
                      >
                        <XMarkIcon className="text-midnight h-5" aria-hidden="true" />
                      </button>
                    ) : null
                  }
                  onChange={(e) => {
                    searchResolvedRepairOrders(e.target.value)
                    setResolvedSearchTerm(e.target.value)
                  }}
                  placeholder="Search"
                  value={resolvedSearchTerm}
                  hideError
                  ref={resolvedSearchInputRef}
                />

                <div className="mt-1 flex flex-wrap items-center gap-2">
                  <Popover className="relative">
                    <PopoverButton
                      className={mergeClassNames(
                        'bg-background border-brownGray-200 hover:bg-charchoal-light hover:border-brownGray-300 rounded-lg border-2 p-3 outline-none ring-0',
                        activeResolvedFilters && 'border-blue-800 hover:border-blue-900',
                      )}
                      data-testid="resolved-repair-orders-filter"
                      aria-label="Filter Repair Orders"
                    >
                      <FilterIcon
                        className={mergeClassNames(
                          'fill-charcoal-400 h-4 w-4',
                          activeResolvedFilters && 'fill-blue-800',
                        )}
                      />
                    </PopoverButton>

                    <PopoverPanel
                      anchor="top"
                      className="z-10 flex w-[420px] flex-col gap-2 !overflow-visible rounded-lg bg-[#ffffff] p-3 shadow-md"
                    >
                      <span className="text-md font-medium">Filters</span>

                      <div className="flex w-full flex-row">
                        <div className="flex w-1/2 flex-col gap-1">
                          <CheckBox
                            label="Ages out in < 7 days"
                            name="agesOut"
                            onChange={() =>
                              setSelectedResolvedFilters({
                                ...selectedResolvedFilters,
                                agesOut: !selectedResolvedFilters.agesOut,
                              })
                            }
                            value={selectedResolvedFilters.agesOut}
                          />

                          <CheckBox
                            label="No update in > 5 days"
                            name="noUpdate"
                            onChange={() =>
                              setSelectedResolvedFilters({
                                ...selectedResolvedFilters,
                                noUpdate: !selectedResolvedFilters.noUpdate,
                              })
                            }
                            value={selectedResolvedFilters.noUpdate}
                          />

                          <CheckBox
                            label="High dollar (> $10,000)"
                            name="highDollarResolved"
                            onChange={() =>
                              setSelectedResolvedFilters({
                                ...selectedResolvedFilters,
                                highDollarResolved: !selectedResolvedFilters.highDollarResolved,
                              })
                            }
                            value={selectedResolvedFilters.highDollarResolved}
                          />
                        </div>

                        <div className="flex w-1/2 flex-col gap-1">
                          <CheckBox
                            label="New in last 7 days"
                            name="new"
                            onChange={() =>
                              setSelectedResolvedFilters({
                                ...selectedResolvedFilters,
                                new: !selectedResolvedFilters.new,
                              })
                            }
                            value={selectedResolvedFilters.new}
                          />

                          <CheckBox
                            label="Resolved in last 7 days"
                            name="resolved"
                            onChange={() =>
                              setSelectedResolvedFilters({
                                ...selectedResolvedFilters,
                                resolved: !selectedResolvedFilters.resolved,
                              })
                            }
                            value={selectedResolvedFilters.resolved}
                          />
                        </div>
                      </div>

                      <MultiSelectDropdown
                        className="bg-[#ffffff]"
                        id="action-needed"
                        label="Action Needed"
                        listBoxClassName="bg-[#ffffff]"
                        onChange={(o) =>
                          setSelectedResolvedFilters({
                            ...selectedResolvedFilters,
                            actionNeeded: o,
                          })
                        }
                        options={actionsNeeded}
                        value={selectedResolvedFilters.actionNeeded}
                      />

                      <MultiSelectDropdown
                        className="bg-[#ffffff]"
                        id="advisor"
                        label="Advisor Number"
                        listBoxClassName="bg-[#ffffff]"
                        onChange={(o) =>
                          setSelectedResolvedFilters({
                            ...selectedResolvedFilters,
                            advisor: o,
                          })
                        }
                        options={advisors}
                        value={selectedResolvedFilters.advisor}
                      />

                      <MultiSelectDropdown
                        className="bg-[#ffffff]"
                        id="technician"
                        label="Technician"
                        listBoxClassName="bg-[#ffffff]"
                        onChange={(o) =>
                          setSelectedResolvedFilters({
                            ...selectedResolvedFilters,
                            technician: o,
                          })
                        }
                        openAbove
                        options={technicians}
                        value={selectedFilters.technician}
                      />
                    </PopoverPanel>
                  </Popover>
                  {activeResolvedFilters && (
                    <button
                      className="cursor-pointer rounded-md text-sm font-semibold leading-5 tracking-[0.25px] text-blue-800 hover:text-blue-900"
                      onClick={() => setSelectedResolvedFilters(DEFAULT_FILTERS)}
                      type="button"
                    >
                      Reset Filters
                    </button>
                  )}
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <Button
                  className="px-1 py-2"
                  dataTestId="export-resolved-repair-orders"
                  disabled={loadingExport}
                  label={<DownloadIcon className="h-4 stroke-[#ffffff]" />}
                  ariaLabel="Export Resolved Repair Orders"
                  onClick={async () => {
                    const baseParams = '?state=Resolved'
                    const params =
                      urlParamsResolvedRepairOrders === ''
                        ? baseParams
                        : `${baseParams}&${urlParamsResolvedRepairOrders}`
                    const exportTask = await createRepairOrderExport(
                      `/dealers/${id}/repair-order-exports/${params}`,
                      handleErrors,
                      setLoadingExport,
                    )

                    if (exportTask) {
                      createNewTask('Download', exportTask, {
                        label: 'Repair Orders',
                        filename: `${updatedDealer.name} - Resolved Repair Orders.xlsx`,
                        checkUpdatedTask: async () => {
                          const updatedTask = await getRepairOrderExport(
                            id,
                            exportTask.id,
                            handleErrors,
                          )
                          return updatedTask
                        },
                      })
                    }
                  }}
                />
              </div>
            </div>

            <DataTable
              onRowClicked={(row) => navigate(`/dealer/${row.dealer}/repair-order/${row.id}`)}
              highlightOnHover
              columns={RESOLVED_COLUMNS}
              data={resolvedRepairOrders}
              expandableIcon={{
                collapsed: (
                  <div className="flex flex-row gap-2">
                    <div className="bg-charcoal-light h-[38px] w-2 rounded-r-[4px]" />
                    <AddIcon className="stroke-charcoal-light h-4" />
                  </div>
                ),
                expanded: (
                  <div className="flex flex-row gap-3">
                    <div className="bg-gold-dark h-[38px] w-2 rounded-r-[4px]" />
                    <MinusIcon className="stroke-charcoal-light h-4" />
                  </div>
                ),
              }}
              expandableRows
              expandableRowsComponent={(rowData) =>
                renderExpandableComponent(rowData, false, false)
              }
              onChangePage={(page) =>
                handlePagination(
                  page,
                  resolvedCurrentPage,
                  resolvedPerPage,
                  resolvedTotalRows,
                  resolvedPages,
                  setResolvedCurrentPage,
                  (url) => getUpdatedRepairOrders(url, 'Resolved'),
                  DEALER_BASE_URL,
                  `order_by=${resolvedSortedColumn}`,
                )
              }
              onChangeRowsPerPage={(currentRowsPerPage) => setResolvedPerPage(currentRowsPerPage)}
              onSort={(column, direction) => {
                const d = direction === 'asc' ? '' : '-'
                setResolvedSortedColumn(`${d}${column.sortBy}`)
              }}
              pagination
              paginationServer
              paginationPerPage={resolvedPerPage}
              paginationRowsPerPageOptions={[10, 20, 30, 50]}
              paginationTotalRows={resolvedTotalRows}
              progressPending={loadingResolved}
              sortServer
            />
          </CollapsibleSection>
        </div>
      </StateContainer>

      <Modal
        open={isConfirmationModalVisible}
        title="Are you sure?"
        setOpen={setIsConfirmationModalVisible}
        content={
          <div className="my-8 flex h-full items-center justify-center">
            <p className="text-center">
              {`Please confirm that you'd like to return the repair order to ${
                repairOrderPayload.markAsDealerReview ? 'Dealer' : 'WPC'
              }.`}
            </p>
          </div>
        }
        actions={[
          {
            type: 'submit',
            label: 'Confirm',
            onClick: async () => {
              // Call the update function
              await updateRepairOrder(
                id,
                selectedRepairOrderId,
                repairOrderPayload,
                setLoadingOutstanding,
                handleErrors,
                () => {
                  handleSuccess(
                    `Repair order returned to ${
                      repairOrderPayload.markAsDealerReview ? 'Dealer' : 'WPC'
                    }.`,
                  )

                  // Update the KPIs and repair orders
                  getUpdatedRepairOrderKpis(`${KPI_BASE_URL}&${urlParams}`)
                  const params = urlParams === '' ? '' : `&${urlParams}`
                  getUpdatedRepairOrders(
                    `${DEALER_BASE_URL}&limit=${wpcPerPage}&page=1&order_by=${wpcSortedColumn}${params}`,
                    'WPC Review',
                  )
                  getUpdatedRepairOrders(
                    `${DEALER_BASE_URL}&limit=${dealerPerPage}&page=1&order_by=${dealerSortedColumn}${params}`,
                    'Dealer Review',
                  )

                  setIsConfirmationModalVisible(false)
                },
              )
            },
          },
          { type: 'cancel', label: 'Cancel' },
        ]}
      />
    </div>
  )
})

export default DealerDetails
