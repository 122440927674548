import { action, observable } from 'mobx'
import { createContext } from 'react'

// Store
import { BaseStore } from './BaseStore'

const INITIAL_STATE = {
  currentPage: 1,
  nextPage: null,
  notifications: [],
}

const TYPES = {
  currentPage: observable,
  nextPage: observable,
  notifications: observable,
}

export class NotificationStore extends BaseStore {
  get unreadNotifications() {
    return this.notifications.filter((notification) => notification.unread)
  }

  get readNotifications() {
    return this.notifications.filter((notification) => !notification.unread)
  }

  get archivedNotifications() {
    return this.notifications.filter((notification) => notification.archived)
  }

  setCurrentPage = action((page) => {
    this.currentPage = page
  })

  setNotifications = action((response, page) => {
    if (response) {
      // If the page is 1, set the notifications to the response results
      // Otherwise, append the response results to the existing notifications
      this.notifications =
        page === 1 ? response.results : [...this.notifications, ...response.results]
      this.nextPage = response.next
    }
  })
}

export const notifications = new NotificationStore(INITIAL_STATE, TYPES)
export const NotificationStoreContext = createContext(notifications)
