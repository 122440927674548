import { observer } from 'mobx-react'
import { React, useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

// Components
import { Button } from '../../components/Button'
import { FormHeader } from '../../components/FormHeader'
import { VerificationCodeInput } from '../../components/VerificationCodeInput'

// Store
import { UserStoreContext } from '../../stores/UserStore'

// Service & Utils
import { loginConfirm, resendLoginCode } from '../../services/user.service'
import { toast } from '../../utils/helpers'

const CODE_LENGTH = 6
const MFA_OPTIONS = ['Email', 'Phone']

/**
 *
 * MultiFactorAuthentication
 *
 */
const MultiFactorAuthentication = observer(() => {
  // Context
  const navigate = useNavigate()
  const location = useLocation()
  const { getUpdatedUser, setCurrentTokens, user, setCurrentUser } = useContext(UserStoreContext)

  // State
  const [device, setDevice] = useState()
  const [loading, setLoading] = useState(false)

  const handleSuccess = (message) => toast(message, 'success')
  const handleErrors = (message) => toast(message, 'error')

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: null,
    },
  })

  const { handleSubmit: handleSubmitResend, setValue: setValueResend } = useForm()

  /**
   * Handles form submission
   * - Redirects to dashboard if successful
   * @param {object} data
   */
  const submitForm = async (data) => {
    const result = await loginConfirm(
      { code: data.code, email: user.email },
      handleErrors,
      setLoading,
      setCurrentTokens,
      getUpdatedUser,
    )

    if (result) {
      const nextUrl = location.state?.next || '/dashboard'
      navigate(nextUrl, { state: { isFromVerify: true } })
    }
  }

  /**
   * Handles re-sending verification form submission
   * @param {object} data
   */
  const submitResendForm = async (data) => {
    const results = await resendLoginCode(
      { ...data, email: user.email, password: location.state.password },
      handleErrors,
      setLoading,
    )

    if (results) {
      setCurrentUser({ email: user.email, ...results })
      handleSuccess(user.message)
    }
  }

  return (
    <div className="flex h-full w-full justify-center px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-5">
        <FormHeader title="Multi-Factor Authentication">
          <div className="flex w-full justify-center">
            <a
              href="/login"
              className="text-sm font-semibold uppercase leading-[14px] tracking-[1.5px] text-blue-800 hover:text-blue-900"
            >
              Log In
            </a>
          </div>
        </FormHeader>

        <form className="flex flex-col space-y-2" onSubmit={handleSubmit(submitForm)}>
          <p className="text-primary my-2 text-center text-sm">
            Enter the verification code sent to <span className="font-bold">{user.address}</span>.
          </p>

          <input type="hidden" name="remember" defaultValue="true" />

          <Controller
            name="code"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <VerificationCodeInput
                error={errors.code && 'This field is required'}
                handleSubmitCode={(v) => onChange(v)}
                containerStyles={{ alignItems: 'center' }}
                codeLength={CODE_LENGTH}
              />
            )}
          />

          <div className="flex flex-col flex-wrap justify-center gap-2 md:flex-nowrap">
            {MFA_OPTIONS.map((mfaOption) => (
              <Button
                disabled={loading && device !== mfaOption}
                type="button"
                key={mfaOption}
                id={mfaOption}
                className="flex h-auto w-full items-center border-blue-800 bg-transparent text-sm font-semibold uppercase leading-[14px] tracking-[1.5px] text-blue-800 hover:bg-transparent hover:text-blue-900 md:w-auto"
                label={`Resend Verification via ${mfaOption}`}
                loading={loading && device === mfaOption}
                onClick={() => {
                  setDevice(mfaOption)
                  setValueResend('mfaDevice', mfaOption)
                  handleSubmitResend(submitResendForm)()
                }}
              />
            ))}
          </div>

          <Button loading={loading} label="Verify" fullWidth />
        </form>
      </div>
    </div>
  )
})

export default MultiFactorAuthentication
