import React from 'react'
import { isPlainObject } from 'lodash'
import { useImmer } from 'use-immer'
import { toast as displayToast } from 'react-hot-toast'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Component
import { Alert } from '../components/Alert'
import {
  oneUppercaseLetterRegEx,
  oneLowercaseLetterRegEx,
  oneNumberRegEx,
  oneSymbolRegEx,
} from '../components/PasswordVerifier'

// Action Icons
import { ActionAddIcon } from '../components/ActionAddIcon'
import { ActionCodeIcon } from '../components/ActionCodeIcon'
import { ActionDollarIcon } from '../components/ActionDollarIcon'
import { ActionIdBadgeIcon } from '../components/ActionIdBadgeIcon'
import { ActionPaperIcon } from '../components/ActionPaperIcon'
import { ActionPaperCheckIcon } from '../components/ActionPaperCheckIcon'
import { ActionPersonIcon } from '../components/ActionPersonIcon'
import { ActionMemoCheckIcon } from '../components/ActionMemoCheckIcon'
import { ActionMileageIcon } from '../components/ActionMileageIcon'
import { ActionMoreIcon } from '../components/ActionMoreIcon'
import { ActionUploadIcon } from '../components/ActionUploadIcon'

const ERROR_DEFAULT = 'An error occurred. Please try again.'
const ERROR_NO_INTERNET = 'Request could not be made. Please check your internet connection.'
const ERROR_TIMEOUT = 'Request timed out. Please try again.'

export const createWithDoc = ({ envName = '', docFunction = () => {}, component = '' }) => {
  let createComponentWithDoc
  if (envName !== 'production') {
    createComponentWithDoc = docFunction(component) // eslint-disable-line global-require
  }
  return createComponentWithDoc || component
}

export const getErrorMessage = (err) => {
  // - Axios does not return a response object if the service cannot be reached
  if (err.code === 'ERR_NETWORK') {
    return ERROR_NO_INTERNET
  }

  if (err.code === 'ECONNABORTED' || err.code === 'ETIMEDOUT') {
    return ERROR_TIMEOUT
  }

  // - If we do have a response and data object, use the full error object
  if (err.response && err.response.data) {
    try {
      const errorStringOrObject = Object.values(err.response.data)[0]
      if (isPlainObject(errorStringOrObject)) return errorStringOrObject[0]
      return errorStringOrObject
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Could not infer error message from error response object')
    }
  }

  // If a message has not been set, fallback to our default message
  return ERROR_DEFAULT
}

/**
 * Updates the state object with the updated field.
 * @param {object} state
 * @param {object} updatedField
 */
export const updateState = (state, updatedField) => ({
  ...state,
  ...updatedField,
})

/**
 * Updates an array stored in state.
 * - `add`: adds value to the array
 * - `remote`: removes the value at the index from the array
 * - `update`: updates the value at the index in the array
 * - `reset` and `filter`: returns the supplied value
 * - `clear`: removes all values from the array
 * @param {object} state
 * @param {object} updatedState
 */
export const updateArrayState = (state, { type, value, index }) => {
  switch (type) {
    case 'add':
      return [...state, value]
    case 'remove': {
      const updatedState = [...state]
      updatedState.splice(index, 1)
      return updatedState
    }
    case 'update': {
      const updatedState = [...state]
      updatedState[index] = value
      return updatedState
    }
    case 'reset':
    case 'filter':
      return value
    case 'clear':
      return []
    default:
      return state
  }
}

export const joinClassNames = (...classes) => classes.filter(Boolean).join(' ')

export const useImmerState = useImmer

export const toast = (message, type) =>
  displayToast.custom(
    (t) =>
      t.visible && (
        <Alert message={message} type={type} onClose={() => displayToast.dismiss(t.id)} />
      ),
  )

/**
 * Handles updating pagination based on the supplied parameters and functions.
 * @param {number} page
 * @param {number} currentPage
 * @param {number} perPage
 * @param {number} totalRows
 * @param {object} pages
 * @param {func} setCurrentPage
 * @param {func} request
 * @param {string} baseUrl
 * @param {string} filter
 */
export const handlePagination = async (
  page,
  currentPage,
  perPage,
  totalRows,
  pages,
  setCurrentPage,
  request,
  baseUrl,
  filter = null,
) => {
  // If the user is requesting the first page and we are not on the next page,
  // we need to get the very first page and not utilize `previous`.
  if (page === 1 && currentPage > 1) {
    await request(`${baseUrl}${perPage}&${filter ? `${filter}&` : ''}page=1`)
  }
  // If the user is requesting the last page and we are not on the previous page,
  // we need to get the very last page and not utilize `next`.
  else if (page > currentPage && page - currentPage > 1) {
    await request(
      `${baseUrl}${perPage}&${filter ? `${filter}&` : ''}page=${Math.ceil(totalRows / perPage)}`,
    )
  }
  // If the user is requesting the next page.
  else if (page > currentPage) {
    await request(pages.next)
  }
  // Otherwise, the user is requesting the previous page.
  else {
    await request(pages.previous)
  }

  setCurrentPage(page)
}

/**
 * Handles verifying the password against requirements.
 * @param {string} value
 * @returns {string} error if there is one to display
 */
export const verifyPassword = (value) => {
  if (!value || value.length < 8) return 'Please enter more than 8 characters'
  if (!oneUppercaseLetterRegEx.test(value)) return 'Please enter at least 1 uppercase letter'
  if (!oneLowercaseLetterRegEx.test(value)) return 'Please enter at least 1 lowercase letter'
  if (!oneNumberRegEx.test(value)) return 'Please enter at least 1 number'
  if (!oneSymbolRegEx.test(value)) return 'Please enter at least 1 symbol'
  return undefined
}

/**
 * Formats the specified `phoneNumber`.
 * @param string phoneNumber
 * @returns
 */
export const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber) {
    const cleaned = ('', phoneNumber).replace(/\D/g, '')
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

    if (match) {
      const intlCode = match[1] ? '+1 ' : ''
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
  }
  return ''
}

/**
 * Configures the icon for the specified `icon` string and `completed` status.
 * @param {string} icon
 * @param {boolean} completed
 * @returns icon component
 */
export const configureActionItemIcon = (icon, completed = false) => {
  switch (icon) {
    case 'paper-check':
      return (
        <ActionPaperCheckIcon
          className={mergeClassNames('stroke-blue h-6 w-6', completed && 'stroke-green')}
        />
      )
    case 'code':
      return (
        <ActionCodeIcon
          className={mergeClassNames('stroke-blue h-6 w-6', completed && 'stroke-green')}
        />
      )
    case 'upload':
      return (
        <ActionUploadIcon
          className={mergeClassNames('stroke-blue h-6 w-6', completed && 'stroke-green')}
        />
      )
    case 'person':
      return (
        <ActionPersonIcon
          className={mergeClassNames('stroke-blue h-6 w-6', completed && 'stroke-green')}
        />
      )
    case 'memo-check':
      return (
        <ActionMemoCheckIcon
          className={mergeClassNames('stroke-blue h-6 w-6', completed && 'stroke-green')}
        />
      )
    case 'mileage':
      return (
        <ActionMileageIcon
          className={mergeClassNames('stroke-blue h-6 w-6', completed && 'stroke-green')}
        />
      )
    case 'add':
      return (
        <ActionAddIcon
          className={mergeClassNames('stroke-blue h-6 w-6', completed && 'stroke-green')}
        />
      )
    case 'paper':
      return (
        <ActionPaperIcon
          className={mergeClassNames('stroke-blue h-6 w-6', completed && 'stroke-green')}
        />
      )
    case 'more':
      return (
        <ActionMoreIcon
          className={mergeClassNames('stroke-blue h-6 w-6', completed && 'stroke-green')}
        />
      )
    case 'dollar':
      return (
        <ActionDollarIcon
          className={mergeClassNames('stroke-blue h-6 w-6', completed && 'stroke-green')}
        />
      )
    case 'id-badge':
      return (
        <ActionIdBadgeIcon
          className={mergeClassNames('stroke-blue h-6 w-6', completed && 'stroke-green')}
        />
      )
    default:
      return null
  }
}
